import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../App'
import { Error404Page } from '../components/error404/Error404Page'
import { FormContact } from '../components/contact/contactPage'
import { PrivacyPolicy } from '../components/privacy-policy/privacyPolicy'
export const AppRoutes = () => {
  return (
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='/*' element={<Error404Page />} />            
            <Route path='/contact' element={<FormContact />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
  )
}