import React from 'react';
import { Header } from './components/header/Header'
import { Footer } from './components/footer/Footer'
import TwoColMultiMedia from './components/twocolmultimedia';
import backgroundVideo from './assets/images/background-top.mp4';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div>
      <Header />
      <div className="relative overflow-hidden min-h-[calc(100vh_-_73px)] md:h-[980px] flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-cover bg-center bg-no-repeat background-video">
          <video
            className="absolute inset-0 w-full h-full object-cover"
            src={backgroundVideo}
            autoPlay
            loop
            muted
            playsInline
          ></video></div>
          <div className="container mx-auto relative z-10 h-full px-4">
            <div className="lg:w-8/12 w-full mx-auto h-full flex flex-col">
              <div className="flex flex-col gap-y-8 h-full justify-center py-[60px] flex-1 lg:text-center">
                <div className="text-content cms-content">
                  <h1 className="text-white text-4xl font-bold">Sólo hay un límite...<br />Tu imaginación.</h1>
                  <p className="text-white">Construimos productos digitales a la medida,<br />para impulsar el crecimiento de tu empresa, negocio o emprendimiento.</p>
                </div>
                <div className="flex lg:flex-nowrap flex-wrap gap-x-4 gap-y-4 justify-center">
                  <a className="btn btn--primary py-2 px-4 rounded" to="https://api.whatsapp.com/send/?phone=584122540247&text&type=phone_number&app_absent=0">COTIZAR AHORA</a>
                  <a className="btn btn--secondary py-2 px-4 rounded" href="#services">Más información</a>
                </div>
              </div>
            </div>
          </div>
        </div>
          <section id="scale-your-team" className="pt-20 sm:pt-32 lg:pt-40 pb-10 sm:pb-16 lg:pb-20 mb-16 relative">
            <div className="container mx-auto">
              <div className="lg:text-center lg:justify-center lg:items-center relative z-10 flex">
                <div className="w-full lg:w-10/12 flex flex-col gap-y-8">
                  <div className="text-content cms-content" id="services">
                    <h2 className="text-4xl font-bold">
                      <span>TE OFRECEMOS APOYO</span><br />
                      <span>INTEGRAL DESDE EL DISEÑO</span><br />
                      <span style={{ color: '#44bb89' }}>HASTA EL LANZAMIENTO</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <TwoColMultiMedia
            imageSrc="appweb.png"
            imageAlt="APLICACIONES WEB"
            title="APLICACIONES WEB"
            text="Desarrollamos aplicaciones web personalizadas para tu negocio, combinando diseño innovador y funcionalidad avanzada. Garantizamos soluciones rápidas, seguras y adaptables, que potencian tu presencia en línea y optimizan tus procesos."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
          />
          <TwoColMultiMedia
            imageSrc="ecommerce.png"
            imageAlt="E-COMMERCE"
            title="E-COMMERCE"
            text="Desarrollamos tiendas de e-commerce personalizadas que capturan la atención de tus clientes. Diseñamos plataformas intuitivas, seguras y optimizadas para todos los dispositivos, facilitando una experiencia de compra fluida y efectiva."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
            reverse
          />
          <TwoColMultiMedia
            imageSrc="appmobile.png"
            imageAlt="APLICACIONES MÓVILES"
            title="APLICACIONES MÓVILES"
            text="Creamos aplicaciones móviles a medida para iOS y Android, ofreciendo una experiencia de usuario excepcional. Desde la idea inicial hasta el lanzamiento, desarrollamos soluciones que cumplen tus objetivos y destacan en el mercado."
            buttonText="COTIZAR AHORA"
            buttonUrl="/contact"
          />
        <Footer />
    </div>
  );
}

export default App;
