import React, { useState } from 'react';
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
export const FormContact = () => {

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
    
      const [errors, setErrors] = useState({});
    
      const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    
      const validatePhone = (phone) => {
        const regex = /^\+?\d{10,15}$/;
        return regex.test(phone);
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};
    
        if (!formData.fullName) {
          newErrors.fullName = 'Full name is required';
        }
        if (!validateEmail(formData.email)) {
          newErrors.email = 'Invalid email address';
        }
        if (!validatePhone(formData.phone)) {
          newErrors.phone = 'Invalid phone number';
        }
        if (!formData.subject) {
          newErrors.subject = 'Subject is required';
        }
        if (!formData.message) {
          newErrors.message = 'Message is required';
        }
    
        setErrors(newErrors);
    
        if (Object.keys(newErrors).length === 0) {
          console.log('Form data submitted:', formData);
          /*resend.emails.send({
            from: 'onboarding@resend.dev',
            to: 'aalexsander@gmail.com',
            subject: 'Enviado desde MOID TECH',
            html: `<p>Información enviada desde el formulario <strong>Nombre Completo: </strong>${formData.fullName}<br/>
            <strong>Correo electrónico: </strong>${formData.email}<br/>
            <strong>Teléfono: </strong>${formData.phone}<br/>
            <strong>Asunto: </strong>${formData.subject}<br/>
            <strong>Mensaje: </strong>${formData.message}<br/></p>`
          });*/
        }
      };
  return (
      <>
        <Header />
        <div style={{marginTop: "20px"}}></div>
        <section className="pt-0 pb-10 relative overflow-hidden">
            <div className="container mx-auto max-w-[1260px]">
                <div className={`flex flex-col lg:flex-row gap-x-[86px] gap-y-8`}>
                <div className={`w-full flex lg:justify-start lg:items-center transition-opacity duration-700 ease-out opacity-100 translate-x-0`}>
                    <div className="flex flex-col gap-y-8">
                    <div className="text-content cms-content text-justify">
                        <h3 className="text-2xl font-semibold"><span>Contáctenos</span></h3>
                        <p className="mt-4 text-sm"><span>
                        Para ponerte en contacto con nosotros en relación a nuestros servicios, te invitamos a escribirnos utilizando 
                        nuestro formulario ubicado abajo, también puedes llamarnos o escribirnos vía whatsapp al +58 (412) 254-0247</span></p>
                        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen">
                        <div className="w-full lg:w-1/2 p-8">
                            <form onSubmit={handleSubmit} className="space-y-2">
                            <div>
                                <label className="block text-gray-700">Nombre Completo</label>
                                <input
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="w-full m-0 p-0 border border-gray-300 rounded"
                                placeholder="Ingresa tu nombre"
                                />
                                {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
                            </div>

                            <div>
                                <label className="block text-gray-700">Correo Electrónico</label>
                                <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full m-0 p-0 border border-gray-300 rounded"
                                placeholder="Ingresa tu correo"
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>

                            <div>
                                <label className="block text-gray-700">Teléfono</label>
                                <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="w-full m-0 p-0 border border-gray-300 rounded"
                                placeholder="Ingresa tu teléfono"
                                />
                                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                            </div>

                            <div>
                                <label className="block text-gray-700">Asunto</label>
                                <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                className="w-full m-0 p-0 border border-gray-300 rounded"
                                placeholder="En que podemos ayudarte"
                                />
                                {errors.subject && <p className="text-red-500 text-sm">{errors.subject}</p>}
                            </div>

                            <div>
                                <label className="block text-gray-700">Message</label>
                                <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full mt-1 p-2 border border-gray-300 rounded"
                                placeholder="Escribe tu mensaje, en que podemos ayudarte"
                                rows="4"
                                ></textarea>
                                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                            </div>
                            <button
                                type="submit"
                                className="w-full btn btn--primary py-2 px-4 rounded"
                            >
                                Enviar mensaje
                            </button>
                            </form>
                        </div>

                        <div className="w-full lg:w-1/2 p-8 flex items-center justify-center">
                            <img
                            src="https://via.placeholder.com/400"
                            alt="Contact Us"
                            className="rounded-lg shadow-lg max-w-full"
                            />
                        </div>
                        </div>
                                            
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        <Footer />
      </>
  )
}