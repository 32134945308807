import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const callImages = require.context('../assets/images', true);
const TwoColMultiMedia = ({ imageSrc, imageAlt, title, text, buttonText, buttonUrl, reverse }) => {
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: contentRef, inView: contentInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="pt-0 pb-10 sm:pb-16 lg:pb-20 relative overflow-hidden">
      <div className="container mx-auto max-w-[1260px]">
        <div className={`flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} gap-x-[86px] gap-y-8`}>
          <div
            ref={contentRef}
            className={`w-full lg:w-5/12 flex lg:justify-start lg:items-center transition-opacity duration-700 ease-out ${contentInView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-[-50px]'}`}
          >
            <div className="flex flex-col gap-y-8">
              <div className="text-content cms-content">
                <h3 className="text-2xl font-semibold"><span>{title}</span></h3>
                <p className="mt-4 text-lg"><span>{text}</span></p>
              </div>
              <div className="flex flex-wrap lg:flex-nowrap gap-x-4 gap-y-4">
                {/*<Link className="btn btn--primary bg-blue-600 py-2 px-4 rounded" to={buttonUrl}>
                  {buttonText}
                </Link>*/}
                <a href='https://api.whatsapp.com/send/?phone=584122540247&text&type=phone_number&app_absent=0'  className="btn btn--primary bg-blue-600 py-2 px-4 rounded">{buttonText}</a>
              </div>
            </div>
          </div>
          <div
            ref={imageRef}
            className={`w-full lg:w-7/12 flex justify-center lg:justify-start lg:items-center transition-transform duration-700 ease-out ${imageInView ? 'translate-x-0' : 'translate-x-[50px]'}`}
          >
            <img
              className="rounded-[12px] max-w-full"
              src={callImages(`./${imageSrc}`)}
              alt={imageAlt}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColMultiMedia;
