import React from 'react'
import { Link } from 'react-router-dom';
import Whatsapp from '../whatsapp/whatsapp';

const callImages = require.context('../../assets/images', true);
const handleTopHome = () => {
    window.scrollTo(0, 0);
}
export const Footer = () => {
  return (
    <><Whatsapp />
    <footer className="main_footer main-footer bg-[#000000] [&_h4]:text-light [&_h4]:mb-0 text-light pt-16 lg:pt-20 xl:pt-29">
      <div className="container mx-auto px-4 max-w-screen-2xl">
        <div className="grid grid-cols-1 md:grid-cols-[repeat(5,minmax(0,auto))] gap-8 md:gap-6 w-full">
          <div className="flex flex-1 flex-col gap-y-4">
            <div className="flex flex-col gap-y-6">
              <span className="block font-bold leading-[120%] tracking-[0.16px]">MOID Tech</span>
              <ul className="flex flex-col gap-y-5">
                <li className="footer__item group mb-0">
                  <Link to="/about"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    Acerca de
                  </Link>
                </li>
                <li className="footer__item group mb-0">
                  <Link to="/privacy-policy"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    Políticas de Privacidad
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-y-4">
            <div className="flex flex-col gap-y-6">
              <span className="block font-bold leading-[120%] tracking-[0.16px]">Nosotros</span>
              <ul className="flex flex-col gap-y-5">
                <li className="footer__item group mb-0">
                  <Link to="/faq"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    FAQs
                  </Link>
                </li>
                <li className="footer__item group mb-0">
                  <Link to="/moid-team"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    MOID Team
                  </Link>
                </li>
                <li className="footer__item group mb-0">
                  <Link to="/clients"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    Nuestros Clients
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-y-4">
            <div className="flex flex-col gap-y-6">
              <span className="block font-bold leading-[120%] tracking-[0.16px]">Desarrolladores</span>
              <ul className="flex flex-col gap-y-5">
                <li className="footer__item group mb-0">
                  <Link to="/jobs"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    Buscas empleo?
                  </Link>
                </li>
                <li className="footer__item group mb-0">
                  <Link to="/blog"  className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-y-4">
            <div className="flex flex-col gap-y-6">
              <span className="block font-bold leading-[120%] tracking-[0.16px]">Redes sociales</span>
              <ul className="flex flex-col gap-y-5">
                <li className="footer__item group mb-0">
                  <a className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]" href="https://www.linkedin.com/in/moid-tech/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </li>
                <li className="footer__item group mb-0">
                  <a className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]" href="https://x.com/moidtech" target="_blank" rel="noopener noreferrer">X or Twitter</a>
                </li>
                <li className="footer__item group mb-0">
                  <a className="footer__link text-gray block text-lg hover:text-[#B3B3B3] transition-colors duration-200 leading-[120%] tracking-[0.16px]" href="https://www.instagram.com/moid.tech/" target="_blank" rel="noopener noreferrer">Instagram</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 max-w-screen-2xl mt-8">
        <hr />
        <div className="grid grid-cols-1 gapx-2 sm:grid-cols-2 pt-6 pb-12 w-full">
          <div className="flex flex-1">
            <Link to="/" onClick={handleTopHome}>
                <img src={callImages('./moidtech.png')} alt='MOID Tech' loading="lazy" width="100" height="40" />
            </Link>
          </div>
          <div className="footer-bottom-text flex flex-1 pt-6 sm:pt-0 sm:justify-end items-center">
            <p>2024 © MOID Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}