import React from 'react'
import { Link } from 'react-router-dom'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
const callImages = require.context('../../assets/images', true);
export const Error404Page = () => {
  return (
      <>
        <Header />
        <div style={{marginTop: "40px"}}></div>
        <NotFoundSection/>
        <Footer />
      </>
  )
}

const NotFoundSection = () =>{

    return(
        <section className="pt-0 pb-10 relative overflow-hidden">
            <div className="container mx-auto max-w-[1260px]">
                <div className={`flex flex-col lg:flex-row gap-x-[86px] gap-y-8`}>
                <div className={`w-full flex lg:justify-start lg:items-center transition-opacity duration-700 ease-out opacity-100 translate-x-0`}>
                    <div className="flex flex-col gap-y-8">
                    <div className="text-content cms-content text-justify">
                        <h3 className="text-2xl font-semibold"><span>No encontramos lo que buscas</span></h3>
                        <div className='w-full text-center'>
                        <img
                            className="rounded-[12px] max-w-full"
                            src={callImages(`./error-404.jpg`)}
                            alt='No Encontrado'
                            loading="lazy"
                            /></div>
                        <div style={{marginBottom: "60px"}}>
                            <p>Parece que la dirección que colocaste no existe o fue eliminada. Puedes <Link className='NotFound__color-green' to="/">Regresar al inicio</Link> o <Link className='NotFound__color-green' to="/contact">Contactarnos</Link> para obtener más información.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
    )
}