import React from 'react';
import { createRoot } from 'react-dom/client'
import { AppRoutes } from './routes/AppRoutes';
import './index.css';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
      <AppRoutes />
  </React.StrictMode>
);