import { React, useState } from 'react'
import { Link } from 'react-router-dom';
const callImages = require.context('../../assets/images', true);

export const Header = () => {
  const handleTopHome = () => {
      window.scrollTo(0, 0);
  }
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className="px-4 sm:px-5">
      <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
        <header className="w-full">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex justify-between items-center w-full md:w-1/3 lg:w-1/4">
                <Link to="/" onClick={handleTopHome}>
                  <img
                    src={callImages('./moidtechwhite.png')}
                    alt="MOID Tech"
                    loading="lazy"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                    width="100"
                    height="40"
                  />
                </Link>
                <button
                  className="lg:hidden text-gray-700 hover:text-gray-900 focus:outline-none"
                  onClick={toggleMenu}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                className={`${
                  menuOpen ? 'block' : 'hidden'
                } w-full lg:flex md:w-2/3 lg:w-3/4 lg:mt-0 flex flex-col lg:flex-row gap-4 lg:gap-6 mt-4 lg:mt-0 lg:justify-end`}
              >
                <ul className="flex flex-col lg:flex-row w-full lg:w-auto gap-4 lg:gap-6">
                  {/*<li className="w-full lg:w-auto">
                    <Link to="/login" className="block w-full lg:w-auto text-blue-600 hover:text-blue-800 text-lg font-medium text-center lg:text-left">Iniciar Sesión</Link>
                  </li>
                  <li className="w-full lg:w-auto">
                    <Link to="/registro" className="block w-full lg:w-auto text-blue-600 hover:text-blue-800 text-lg font-medium text-center lg:text-left">Regístrate</Link>
                  </li>*/}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
    )
}