import React from 'react'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
export const PrivacyPolicy = () => {
  return (
      <>
        <Header />
        <div style={{marginTop: "20px"}}></div>
        <section className="pt-0 pb-10 relative overflow-hidden">
            <div className="container mx-auto max-w-[1260px]">
                <div className={`flex flex-col lg:flex-row gap-x-[86px] gap-y-8`}>
                <div className={`w-full flex lg:justify-start lg:items-center transition-opacity duration-700 ease-out opacity-100 translate-x-0`}>
                    <div className="flex flex-col gap-y-8">
                    <div className="text-content cms-content text-justify">
                        <h3 className="text-2xl font-semibold"><span>Políticas de Privacidad</span></h3>
                        <p className="mt-4 text-sm"><span>Esta Política de Privacidad describe cómo MOID («nosotros», «nuestro» o «sitio web») recopila, utiliza y protege la información personal que usted proporciona en nuestro sitio web, moid.tech. Por favor, lea atentamente esta política antes de utilizar o enviar cualquier información a través de nuestro sitio web.</span></p>
                        <p className="mt-4 text-sm"><span><b>Recopilación de información</b><br/>Cuando usted visita nuestro sitio web, es posible que recopilemos cierta información personal que usted decida proporcionarnos voluntariamente. Esto puede incluir su nombre, dirección de correo electrónico, número de teléfono y cualquier otra información que elija proporcionar al comunicarse con nosotros a través de nuestro formulario de contacto o por correo electrónico.</span></p>
                        <p className="mt-4 text-sm"><span>Además, es posible que recopilemos cierta información automáticamente cuando usted utiliza nuestro sitio web. Esta información puede incluir su dirección IP, tipo de navegador, proveedor de servicios de Internet (ISP), páginas de referencia/salida, sistema operativo, sello de fecha/hora y datos de clics para analizar las tendencias y administrar el sitio.</span></p>
                        <p className="mt-4 text-sm"><span><b>Uso de la información</b><br/>Utilizamos la información recopilada para los siguientes fines:
                            <ul className="text-sm">
                                <li className="text-sm">Proporcionar y mantener nuestro servicio: Utilizamos la información que usted nos proporciona para responder a sus consultas y brindarle la asistencia técnica necesaria.</li>
                                <li className="text-sm">Comunicación con usted: Podemos utilizar su dirección de correo electrónico para enviarle información relevante sobre nuestros servicios, actualizaciones del sitio web, boletines informativos y promociones. Si en algún momento desea dejar de recibir dichas comunicaciones, puede optar por darse de baja siguiendo las instrucciones proporcionadas en cada correo electrónico o poniéndose en contacto con nosotros directamente.</li>
                                <li className="text-sm">Mejora de nuestro sitio web: Utilizamos la información recopilada para analizar tendencias, administrar el sitio web, realizar un seguimiento del movimiento de los usuarios en el sitio y recopilar información demográfica general para uso interno. Esto nos ayuda a mejorar continuamente nuestro sitio web y ofrecer un mejor servicio a nuestros usuarios.</li>
                            </ul></span></p>
                                            <p className="mt-4 text-sm"><span>
                            <b>Divulgación de información</b><br/>En MOID nos comprometemos a proteger su información personal y no la compartiremos, venderemos ni alquilaremos a terceros, excepto en las siguientes circunstancias limitadas:

                            <ul>
                                <li className="text-sm">Proveedores de servicios: Podemos compartir su información con terceros que nos prestan servicios y nos ayudan en la operación de nuestro sitio web, siempre que estos terceros estén sujetos a acuerdos de confidencialidad adecuados.</li>
                                <li className="text-sm">Cumplimiento de la ley: Nos reservamos el derecho de divulgar su información personal si así lo exige la ley o si creemos de buena fe que dicha divulgación es necesaria para proteger nuestros derechos, cumplir con un proceso legal, responder a una orden judicial o proteger la seguridad de otros.</li>
                            </ul></span></p>
                        <p className="mt-4 text-sm"><span><b>Seguridad de la información</b><br/>Tomamos medidas razonables para proteger la información personal que usted nos proporciona contra accesos no autorizados, pérdida, uso indebido o divulgación. Sin embargo, tenga en cuenta que ninguna transmisión de datos a través de Internet o almacenamiento electrónico es 100% segura. Por lo tanto, no podemos garantizar su seguridad absoluta.</span></p>
                        <p className="mt-4 text-sm"><span><b>Enlaces a otros sitios web</b><br/>Nuestro sitio web puede contener enlaces a otros sitios web de terceros que no están bajo nuestro control. No nos hacemos responsables de las prácticas de privacidad de dichos sitios web. Le recomendamos que revise las políticas de privacidad de esos sitios web antes de proporcionarles cualquier información personal.</span></p>
                        <p className="mt-4 text-sm"><span><b>Cambios en nuestra política de privacidad</b><br/>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en nuestro sitio web. Le recomendamos que revise periódicamente esta página para estar al tanto de las actualizaciones.</span></p>
                        <p className="mt-4 text-sm"><span><b>Contáctenos</b><br/>Si tiene alguna pregunta o inquietud acerca de nuestra Política de Privacidad, por favor contáctenos a través de hola@moid.tech</span></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        <Footer />
      </>
  )
}